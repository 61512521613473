// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Nächste Schritte planen"
        meta={[
          { name: 'description', content: 'Nächste Schritte planen' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]}></Helmet>
      <div
          className='bg__flex--white'
          key='2HSbOn1zD7cILp4lLyaZKP-ISt5hpEqszFMZeG6NuLDL'
          id='die-naechsten-schritte-planen'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "ISt5hpEqszFMZeG6NuLDL",
    "updatedAt": "2020-08-21T09:03:01.755Z",
    "title": "Die nächsten Schritte planen",
    "description": "<p>Baugesuch erstellen, Fördergelder anmelden, das Projekt sauber planen - alles Aufgaben, die Vorlaufzeit brauchen - wir kümmern uns darum.</p>\n<p>Bestätigung: Wir melden uns schnellst möglich bei Ihnen, um alle weiteren Schritte zu besprechen.</p>\n",
    "link": {
        "type": "link",
        "id": "4NQkkArruxa1U2vfYeKImy",
        "updatedAt": "2020-08-21T09:02:19.034Z",
        "label": "Mitarbeiter",
        "page": {
            "slug": "team"
        }
    },
    "anchorName": "die-naechsten-schritte-planen",
    "details": []
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
